<template>

  <modele-form
    :title="title"
    :submit-button-text="submitButtonText"
    @submitModeleForm="onSubmit($event)"
  />

</template>

<script>

import router from '@/router'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import ModeleForm from '@/views/modeles/ModeleForm.vue'

export default {
  components: {
    ModeleForm,
  },
  data() {
    return {
      title: "Ajout d'un modèle",
      submitButtonText: 'Ajouter',
    }
  },
  setup() {
    const toast = useToast()
    const onSubmit = formModeleValue => {
      store.dispatch('modeles/addModele', { marqueId: router.currentRoute.params.marqueId, formModeleValue })
        .then(() => {
          router.push({ name: 'modeles-list', params: { id: router.currentRoute.params.marqueId } })
        })
        .catch(error => {
          if (error.status === 409) {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Modèle déjà existant',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: "Erreur lors de l'ajout d'un modèle",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
    }

    return {
      onSubmit,
    }
  },
}
</script>
